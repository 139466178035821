<template>
  <div id="app">
    <div class="top">
      <van-nav-bar
        title="车辆详情"
        left-text=" "
        :left-arrow="true"
        :border="false"
        @click-left="onClickLeft"
        @click-right="onClickRight"
      />
      <div class="background"></div>
    </div>
    <div class="swipe">
      <div class="tip" v-if="carInfo.images">
        <span>{{ carInfo.tip }}</span>
      </div>
      <van-swipe @change="onChange" v-if="carInfo.images">
        <van-swipe-item v-for="item in carInfo.images" :key="item"
          ><van-image width="100%" height="100%" fit="contain" :src="item"
        /></van-swipe-item>
      </van-swipe>
      <div class="no-image" v-if="carInfo.images == undefined">加载中...</div>
    </div>
    <div class="car-info box-shadow">
      <div class="name">
        <span>{{ carInfo.name }}</span>
      </div>
      <div class="slogan">
        <span>{{ carInfo.slogan }}</span>
      </div>
      <div class="price">
        <span>{{ carInfo.price }}</span>
      </div>
      <div class="performance">
        <van-row type="flex" justify="space-around">
          <van-col span="4"
            ><van-image
              src="images/site.png"
              fit="contain"
              width="20px"
              height="20px"
            />
            <span class="intro">{{ carInfo.site }}</span>
          </van-col>
          <van-col span="4"
            ><van-image
              src="images/gear.png"
              fit="contain"
              width="20px"
              height="20px"
            />
            <span class="intro">{{ carInfo.gear }}</span>
          </van-col>
          <van-col span="4"
            ><van-image
              src="images/volume.png"
              fit="contain"
              width="20px"
              height="20px"
            />
            <span class="intro">{{ carInfo.volume }}</span>
          </van-col>
          <van-col span="4"
            ><van-image
              src="images/oil.png"
              fit="contain"
              width="20px"
              height="20px"
            />
            <span class="intro">{{ carInfo.oil }}</span> </van-col
          ><van-col span="4"
            ><van-image
              src="images/engine.png"
              fit="contain"
              width="20px"
              height="20px"
            />
            <span class="intro">{{ carInfo.tank }}</span>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="user-evaluation box-shadow" style="display: none">
      <div class="title">
        <span
          >用户评价({{
            carInfo.evaluations ? carInfo.evaluations.length : 0
          }})</span
        >

        <van-pull-refresh
          v-if="carInfo.evaluations"
          v-model="loading"
          @refresh="loadData"
        >
          <div class="content" v-for="item in carInfo.evaluations" :key="item">
            <div class="line"></div>
            <div>
              <van-image
                class="head"
                :src="item.avatar"
                fit="cover"
                width="40px"
                height="40px"
              />
              <div class="person">
                <span class="name">{{ item.user }}</span>
                <van-image class="grade" :src="item.grade" />
                <span class="time">{{ item.time }}</span>
              </div>
            </div>
            <div style="clear: both"></div>
            <div class="say">
              {{ item.content }}
            </div>
          </div>
        </van-pull-refresh>
        <div v-else><van-empty description="暂无评价" /></div>
      </div>
    </div>
    <div class="user-evaluation box-shadow">
      <div class="title">
        <span
          >用户评价({{
            carInfo.evaluations ? carInfo.evaluations.length : 0
          }})</span
        >

        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          v-if="carInfo.evaluations"
          @refresh="loadData"
        >
          <div class="content" v-for="item in carInfo.evaluations" :key="item">
            <div class="line"></div>
            <div>
              <van-image
                class="head"
                :src="item.avatar"
                fit="cover"
                width="40px"
                height="40px"
              />
              <div class="person">
                <span class="name">{{ item.user }}</span>
                <van-image class="grade" :src="item.grade" />
                <span class="time">{{ item.time }}</span>
              </div>
            </div>
            <div style="clear: both"></div>
            <div class="say">
              {{ item.content }}
            </div>
          </div>
        </van-list>
        <div v-else><van-empty description="暂无评价" /></div>
      </div>
    </div>
    <van-button
      @click="loadData"
      class="submit-button"
      color="linear-gradient(to left, #FDBC41FF, #F88719FF)"
      text="立即预定"
    ></van-button>
  </div>
</template>

<script>
import {
  NavBar,
  ConfigProvider,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Col,
  Row,
  Button,
  Empty,
  Toast,
  PullRefresh,
  List,
} from "vant";
import axios from "axios";
export default {
  name: "App",
  components: {
    [NavBar.name]: NavBar,
    [ConfigProvider.name]: ConfigProvider,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Toast.name]: Toast,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
  },
  data() {
    return { carInfo: {}, loading: false, finished: false };
  },
  methods: {
    loadData() {
      console.log("loading");
      this.loading = true;
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      axios
        .get("car.json")
        .then((response) => {
          // handle success

          if (response.status == 200) {
            console.log(response);
            console.log(this.carInfo);
            this.carInfo = response.data;
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(() => {
          Toast.clear();
          this.loading = false;
        });
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style lang="less" >
body {
  background-color: #f5f6f9;
}
.van-nav-bar {
  background-color: #00000000 !important;
  .van-icon {
    color: #ffffff !important;
  }
  &__title {
    color: #ffffff !important;
  }
}

.top {
  .background {
    margin-top: -50px;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    &:after {
      z-index: -1;
      content: "";
      width: 120%;
      height: 200px;
      position: absolute;
      left: -10%;
      top: 0;
      border-radius: 0 0 60% 60%;
      background-image: linear-gradient(#73a0fdff, #447df2ff);
      overflow: hidden;
    }
  }
}

.swipe {
  padding-bottom: 56%;
  width: 100%;
  position: relative;

  .tip {
    position: absolute;
    font-size: 60px;
    font-style: italic;
    font-weight: bold;
    text-align: center;
    color: #ffffff9f;
    margin: auto;

    left: 0;
    right: 0;
  }

  .van-swipe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .van-swipe__track {
      margin-bottom: 24px;
    }
  }

  .no-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 25%;
    color: white;
  }
}
.box-shadow {
  background-color: white;
  margin: 12px 12px 12px 12px;
  border-radius: 12px;
  box-shadow: 0px 6px 10px 0px #0f002b4b;
  padding: 16px;
}
.car-info {
  .name {
    width: fit-content;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #34373b;
    line-height: 33px;
    margin: auto;
  }
  .slogan {
    width: fit-content;
    border-radius: 2px;
    opacity: 0.4;
    border: 1px solid #858995ff;
    margin: auto;
    margin-top: 1px;
    padding: 1px 5px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #858995ff;
  }
  .price {
    width: fit-content;
    background: #f88719;
    border-radius: 4px;
    margin: auto;
    margin-top: 12px;
    padding: 3px 7px;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }

  .performance {
    margin-top: 24px;
    text-align: center;
    margin-bottom: 8px;
    .intro {
      margin-top: 6px;
      display: block;
      font-size: 12px;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      color: #858995;
      line-height: 17px;
    }
  }
}
.user-evaluation {
  margin-bottom: 60px !important;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #34373b;
    line-height: 22px;
    overflow: hidden;
  }
  .content {
    margin-top: 16px;
    .line {
      height: 1px;
      background-color: #85899533;
      margin-bottom: 12px;
    }
    .head {
      float: left;
      margin-right: 7px;
    }
    .person {
      float: left;
      justify-content: center;
      align-items: center;
      .name {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #34373b;
      }
      .grade {
        margin-left: 10px;
        width: 18px;
        height: 18px;
      }
      .time {
        margin-top: 4px;
        display: block;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #858995;
        line-height: 17px;
      }
    }

    .say {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #34373b;
      line-height: 20px;
      margin-top: 16px;
    }
  }
}
.van-button {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
}
</style>
